
import api from '../../../services/api.service';
import swal from 'sweetalert2';
import router from '../../../router';
import auth from '../../../services/auth.service';
import { i18n } from '../../../i18n/index';

class PaymentGateway {

    forward() {
        swal.fire({
            title: i18n.t('wallet.payment_gateway.order_created.title'),
            text: i18n.t('wallet.payment_gateway.order_created.text'),
            timer: 5000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false
        }).then(() => {
            router.push({name: 'Dashboard'})
        });
    }

    createOrder(product_id, quantity, payment_method, options) {

        if (payment_method === 'bank_transfer') {
            return api.post('wallet/orders/create/bank-transfer', {
                product_id: product_id,
                quantity: quantity,
                options: options
            }).then(response => {
                this.forward();
            }).catch((error) => {
                swal.fire('Something went wrong', error.data.message, 'error');
                return error;
            });
        } else if (payment_method === 'cash_payment' || payment_method === 'cash') {
            return api.post('wallet/orders/create/cash-payment', {
                product_id: product_id,
                quantity: quantity,
                options: options
            }).then(response => {
                this.forward();
            }).catch((error) => {
                swal.fire('Something went wrong', error.data.message, 'error');
                return error;
            });
        } else if (payment_method === 'balance') {
            return auth.requestVerfificationCode().then(code => {
                return api.post('wallet/orders/create/balance', {
                    product_id: product_id,
                    quantity: quantity,
                    options: options,
                    verification_code: code.verification_code,
                }).then(response => {
                    this.forward();
                }).catch((error) => {
                    swal.fire('Something went wrong', error.data.message, 'error');
                    return error;
                });
            });
        } else if (payment_method === 'cannerpay') {
            return api.post('wallet/orders/create/cannerpay', {
                product_id: product_id,
                options: options,
                quantity: quantity
            }).then(response => {
                this.forward();
            }).catch((error) => {
                swal.fire('Something went wrong', error.data.message, 'error');
                return error;
            });
        }
    }

}

export default new PaymentGateway();
