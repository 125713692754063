<template>
  <b-row :class="loading ? 'whirl' : ''" class="text-left">
    <b-col lg="6" class="border-right">
      <div class="text-center text-bold">
        {{ $t('wallet.component.payment_selector.invoice_address') }}
      </div>
      <hr>
      <address-form ref="addressForm" entity-slot="residency" hideConfirmButton="true"/>
    </b-col>
    <b-col lg="6">

      <p class="mb-3">
        {{ $t('wallet.component.payment_selector.payment.heading') }}
      </p>

      <div class="large-button-selector">
        <div class="b mb-2" :class="{selected: payment_method === 'bank_transfer'}"
             v-if="product.payment_methods.indexOf('bank_transfer') !== -1"
             @click="selectPaymentMethod('bank_transfer')">
          <b-row class="p-3">
            <b-col>
              <i class="fad fa-university mr-2"></i>{{
                $t('wallet.component.payment_selector.payment.bank_transfer')
              }}
            </b-col>
          </b-row>
        </div>
        <div class="b mb-2" :class="{selected: payment_method === 'cannerpay'}"
             v-if="product.payment_methods.indexOf('cannerpay') !== -1"
             @click="selectPaymentMethod('cannerpay')">
          <b-row class="p-3">
            <b-col>
              <i class="fab fa-bitcoin mr-2"></i>{{ $t('wallet.component.payment_selector.payment.bitcoin') }}
            </b-col>
          </b-row>
        </div>
        <div class="b mb-2" :class="{selected: payment_method === 'cash'}"
             v-if="product.payment_methods.indexOf('cash') !== -1"
             @click="selectPaymentMethod('cash')">
          <b-row class="p-3">
            <b-col>
              <i class="fad fa-money-bill-wave mr-2"></i>{{
                $t('wallet.component.payment_selector.payment.cash')
              }}
            </b-col>
          </b-row>
        </div>
        <div class="b mb-2"
             :class="{selected: payment_method === 'balance', 'border-warning text-warning': isBalanceTooLow()}"
             v-if="(product.payment_methods.indexOf('balance') !== -1 && balance.amount > 0) || (product.payment_methods.indexOf('balance') !== -1 && product.payment_methods.length === 1)"
             @click="selectPaymentMethod('balance')">
          <b-row class="p-3">
            <b-col>
              <i class="fad fa-wallet mr-2"></i>
              <i18n path="wallet.component.payment_selector.payment.balance"
                    v-if="!isBalanceTooLow()">
                    <span slot="amount" class="text-secondary">
                        <formatted-number :amount="balance.amount" type="fiat"></formatted-number>
                    </span>
              </i18n>
              <i18n path="wallet.component.payment_selector.payment.balance_deposit_required" v-else>
                    <span slot="amount" class="text-secondary">
                        <formatted-number :amount="product.price * quantity - balance.amount"
                                          type="fiat"></formatted-number>
                    </span>
                <span slot="deposit" class="text-secondary">
                        <b-btn class="m-0 p-1" variant="primary">{{ $t('wallet.component.payment_selector.payment.balance_deposit_now') }}</b-btn>
                    </span>
              </i18n>
            </b-col>
          </b-row>
        </div>
      </div>

      <template v-if="quantity_limit > 1 && quantitySelector">
        <hr>
        <div class="card-body bg-transparent" style="background-image: none">
          <b-row class="plant-amount-selector text-center">
            <b-col cols="4" md="2" offset-md="2" offset-xl="2">
              <b-btn class="sub-button" variant="link" @click="subItem(1)"><i class="far fa-minus"></i></b-btn>
            </b-col>
            <b-col cols="4" md="4" xl="4" class="my-auto">
              <b-input class="text-center border-0 p-0 plant-amount-input" v-model="quantity"
                       @change="validateAmount"></b-input>
            </b-col>
            <b-col cols="4" md="2">
              <b-btn class="add-button" @click="addItem(1)" variant="link"><i class="far fa-plus"></i></b-btn>
            </b-col>

          </b-row>
        </div>
        <hr>
      </template>

      <div class="price text-right pt-3">
        <div class="py-3 px-5 text-right font-weight-light ">

          <div style="font-size: 20px" class="muted text-primary">
            <i18n path="wallet.component.payment_selector.summary.excl_vat">
                <span slot="amount" class="text-secondary">
                    <formatted-number :amount="product.price * quantity / (1+product.vat_rate_multiplier)" type="fiat"></formatted-number>
                </span>
            </i18n>
          </div>

          <div style="font-size: 20px" class="muted text-primary">
            <i18n path="wallet.component.payment_selector.summary.incl_vat">
                 <span slot="amount" class="text-secondary">
                      <formatted-number :amount="product.price * quantity - product.price * quantity/(1+product.vat_rate_multiplier)"
                                        type="fiat"></formatted-number>
                 </span>
              <span slot="vat_rate">
                      {{ 100 * product.vat_rate_multiplier }}
                 </span>
            </i18n>
          </div>

          <div class="h2 font-weight-normal">
            <div style="font-size: 20px" class="muted mt-3">{{
                $t('wallet.component.payment_selector.summary.total')
              }}
            </div>
            <formatted-number :amount="product.price * quantity" type="fiat"></formatted-number>
          </div>


        </div>

        <div v-show="payment_method === 'bank_transfer'" class="alert alert-primary">
          {{ $t('wallet.component.payment_selector.summary.bank_hint', {days: product.payable_days}) }}
        </div>

      </div>

      <div class="custom-control custom-checkbox text-right mb-3 mt-3">
        <input type="checkbox" class="custom-control-input" v-model="conditionsConfirmed" name="agreements"
               id="conditionsConfirmed">
        <label class="custom-control-label" for="conditionsConfirmed">

          <i18n path="wallet.component.payment_selector.overview.conditions.agree.agree">
            <a slot="terms_link" target="_blank" :href="terms">{{
                $t('wallet.component.payment_selector.overview.conditions.agree.terms_link')
              }}</a>
          </i18n>
        </label>
      </div>

      <div class="text-right">
          <b-btn class="btn-xl" :disabled="(payment_method === 'balance' && isBalanceTooLow()) || !buyable" variant="info" @click="next()">
            {{ $t('wallet.component.payment_selector.overview.buy_now.label') }}
          </b-btn>

        <div class="mt-2">
          <p v-if="sale_status.starts_in">{{ $t('wallet.component.payment_selector.summary.starts_in', {in: sale_status.starts_in}) }}</p>
          <!--p v-if="sale_status.ends_in">{{ $t('wallet.component.payment_selector.summary.ends_in', {in: sale_status.ends_in}) }}</p-->
          <p v-if="sale_status.ended">{{ $t('wallet.component.payment_selector.summary.ended', {ago: sale_status.ended}) }}</p>
        </div>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import PaymentGateway from '../services/PaymentGateway.service';
import AddressForm from "../../../components/Forms/AddressForm";

export default {
  props: {
    product: Object,
    quantity: {
      type: Number,
      default: 1
    },
    quantityPreselected: Number,
    quantityLimit: Number,
    quantitySelector: {
      type: Boolean,
      default: function() {
        return true;
      }
    },
    options: {
      type: Object,
      default: function() {
        return {}
      }
    },
    validate: {
      type: Function,
      default: function() {
        return true;
      }
    },
    terms: String
  },
  components: {AddressForm},
  data() {
    return {
      loading: false,
      conditionsConfirmed: false,
      quantity_limit: 999999,
      payment_method: null,
      balance: {
        amount: 0,
        currency: {
          label: '',
          shorthand: ''
        }
      },
      buyable: true,
      sale_status: {
        ends_in: false,
        starts_in: false,
        ended: false
      }
    }
  },
  beforeMount() {
    this.quantity = this.quantityPreselected;

    this.quantity_limit = this.product.limit_per_customer || this.quantityLimit;
    if (this.product.sale && this.product.sale.available_units !== undefined) {
      if (this.product.sale.available_units < this.quantity_limit) {
        this.quantity_limit = this.product.sale.available_units
      }
    }

    this.loading = true;
    this.$api.get('wallet/balances').then(response => {
      this.balance = response.data.fiat;

     // if (!this.isBalanceTooLow() || this.product.payment_methods.length !== 1) {
        this.payment_method = this.product.payment_methods[0];
     // }

      if (this.product.sale_start && this.product.sale_end) {
        this.refreshBuyableState();
      }

      this.loading = false;
    });
  },

  methods: {
    refreshBuyableState() {
      this.buyable = this.isBuyable();

      if (this.product.sale && this.product.sale.available_units !== undefined) {
        if (this.product.sale.available_units < 1) {
          this.buyable = false;
        }
      }

      setTimeout(this.refreshBuyableState, 1000);
    },
    isBuyable() {

      //reset status
      this.sale_status = {
        ends_in: false,
        starts_in: false,
        ended: false
      };

      if (this.$moment.utc(this.product.sale_end).local() > this.$moment()) {
        if (this.$moment.utc(this.product.sale_start).local() > this.$moment()) {
          this.sale_status.starts_in = this.$moment.utc(this.product.sale_start).local().fromNow();
          return false;
        } else {
          this.sale_status.ends_in = this.$moment.utc(this.product.sale_end).local().fromNow();
          return true;
        }
      } else {
        this.sale_status.ended = this.$moment.utc(this.product.sale_end).local().fromNow();
        return false;
      }
    },
    validateAmount() {
      this.quantity = parseInt(this.quantity)

      //max amount
      if (this.quantity > this.quantity_limit) {
        this.quantity = this.quantity_limit;
      }

      //min amount
      if (this.quantity < 1) {
        this.quantity = 1;
      }
    },
    addItem(quantity) {
      this.quantity += quantity;
      this.validateAmount();
    },
    subItem(quantity) {
      this.quantity -= quantity;
      this.validateAmount();
    },
    isBalanceTooLow() {
      return this.balance.amount < this.product.price * this.quantity;
    },
    selectPaymentMethod(payment_method) {
      if (payment_method === 'balance' && this.isBalanceTooLow()) {
        this.$router.push({
          name: 'Wallet:ActionMakeDeposit',
          query: {
            amount: Math.ceil(this.product.price * this.quantity - this.balance.amount).toString()
          }
        });
      } else {
        this.payment_method = payment_method;
      }
    },
    next() {
      if (!this.conditionsConfirmed) {
        this.$swal.fire(undefined, this.$t('wallet.component.payment_selector.validation.terms'), 'warning');
        return false;
      }

      if (this.validate()) {
        if (this.$store.getters.user('verification_level') < 2) {
          this.$refs.addressForm.submit().then(() => {
            this.pay();
          }).catch(function (response) {
            //browser based error message thrown here
          });
        } else {
          this.pay();
        }
      }

    },
    pay() {
      this.loading = true;
      PaymentGateway.createOrder(this.product.id, this.quantity, this.payment_method, this.options).then((response) => {
        this.loading = false;
      });
    }
  }
}
</script>